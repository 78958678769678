import React from 'react';
import { Alert, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AWS from 'aws-sdk';
import Layout from '../../layouts/index';

const validate = ({ name, tel, email }) => name && tel && email;

class ContactContent extends React.Component {
  constructor(props) {
    super(props);
    AWS.config.region = 'us-east-1'; // TODO
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-east-1:6423c5f3-2eb7-4c60-b481-fa4c08334f9a',
    }); // TODO
    AWS.config.credentials.get((err) => {
      if (!err) {
        console.log(
          `Cognito Identify Id: ${AWS.config.credentials.identityId}`
        );
      }
    });
    this.changeVal = this.changeVal.bind(this);
    this.send = this.send.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.onDismissAlert = this.onDismissAlert.bind(this);
    this.state = {
      name: '',
      furigana: '',
      tel: '',
      email: '',
      zipcode: '',
      address: '',
      message: '',
      successUploaded: true,
      showModal: false,
      visibleAlert: false,
    };
  }

  changeVal(prop, { target }) {
    this.setState({ [prop]: target.value });
  }
  toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }
  onDismissAlert() {
    this.setState({ visibleAlert: false });
  }
  send() {
    const { successUploaded, showModal, ...inputedData } = this.state;
    if (!validate(inputedData)) {
      this.setState({ visibleAlert: true });
      return;
    }
    const s3RegionName = 'us-east-1'; // TODO
    const s3BucketName = 'contact.hirahata.net'; // TODO
    const s3 = new AWS.S3({
      params: {
        Bucket: s3BucketName,
        Region: s3RegionName,
      },
    });
    const blob = new Blob([JSON.stringify(inputedData, null, 2)], {
      type: 'text/plain',
    });

    s3.putObject(
      {
        Bucket: s3BucketName,
        Key: `${new Date().getTime()}.txt`,
        ACL: 'authenticated-read',
        Body: blob,
        ContentType: 'text/plain',
      },
      (err, data) => {
        if (!err && data !== null) {
          this.setState({
            name: '',
            furigana: '',
            tel: '',
            email: '',
            zipcode: '',
            address: '',
            message: '',
            successUploaded: true,
            showModal: true,
          });
        } else {
          console.log(`Upload Failed: ${err.message}`);
          this.setState({
            successUploaded: false,
            showModal: true,
          });
        }
      }
    );
  }
  render() {
    const {
      name,
      furigana,
      tel,
      email,
      zipcode,
      address,
      message,
      showModal,
      successUploaded,
      visibleAlert,
    } = this.state;
    return (
      <div className="container sub-content">
        <p>
          当サイトより、お問合わせをいただいたお客様には、担当者よりメールまたは電話にてご連絡させていただいております。
        </p>
        <p>
          お問合わせの前に、当院からのメール(info@hirahata-clinic.or.jp)をお受け取りいただけるよう、受信設定の確認をお願いいたします。
        </p>
        <p>
          （現在、送信後にスクロールできない現象が起きていますが、正常に送信はできております。ご迷惑をおかけして大変申し訳ありません。）
        </p>
        <p>
          <font color="red">※</font>印のついている項目は、必須項目です。
        </p>
        <form className="form-horizontal">
          <div className="form-group">
            <label className="col-sm-2 control-label">
              お名前<font color="red">※</font>
            </label>
            <div className="col-sm-10">
              <input
                value={name}
                onChange={(e) => this.changeVal('name', e)}
                type="text"
                className="form-control"
                placeholder="例）健診太郎"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">お名前(フリガナ)</label>
            <div className="col-sm-10">
              <input
                value={furigana}
                onChange={(e) => this.changeVal('furigana', e)}
                type="text"
                className="form-control"
                placeholder="例）ケンシンタロウ"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">
              電話番号<font color="red">※</font>
            </label>
            <div className="col-sm-10">
              <input
                value={tel}
                onChange={(e) => this.changeVal('tel', e)}
                type="text"
                className="form-control"
                placeholder="例）03-1232-56710"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">
              メールアドレス<font color="red">※</font>
            </label>
            <div className="col-sm-10">
              <input
                value={email}
                onChange={(e) => this.changeVal('email', e)}
                type="email"
                className="form-control"
                placeholder="例）kensin@email.com"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">郵便番号</label>
            <div className="col-sm-10">
              <input
                value={zipcode}
                onChange={(e) => this.changeVal('zipcode', e)}
                type="text"
                className="form-control"
                placeholder="例）123-2567"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">ご住所</label>
            <div className="col-sm-10">
              <input
                value={address}
                onChange={(e) => this.changeVal('address', e)}
                type="text"
                className="form-control"
                placeholder="例）東京都渋谷区渋谷1-22-6"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">内容</label>
            <div className="col-sm-10">
              <textarea
                value={message}
                onChange={(e) => this.changeVal('message', e)}
                className="form-control"
                rows="10"
              />
            </div>
          </div>
          <Alert
            color="warning"
            isOpen={visibleAlert}
            toggle={this.onDismissAlert}
          >
            必要事項を明記してください。
          </Alert>
          <div className="form-group">
            <div className="col-sm-offset-2 col-sm-10">
              <button
                onClick={this.send}
                className="btn btn-default"
                type="button"
              >
                送信
              </button>
            </div>
          </div>
        </form>
        <Modal isOpen={showModal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggleModal}>
            {successUploaded ? '送信完了' : '送信失敗'}
          </ModalHeader>
          <ModalBody>
            {successUploaded
              ? 'お問い合わせありがとうございます。こちらからのご連絡をお待ち下さい。'
              : '申し訳ございません、通信エラーかシステムに異常が発生しております。お手数ではございますがお電話にてご連絡頂きますようお願い致します。'}
          </ModalBody>
          <ModalFooter>
            <button
              onClick={this.toggleModal}
              type="button"
              className="btn btn-default"
            >
              閉じる
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const Contact = (props) => (
  <Layout>
    <ContactContent {...props} />
  </Layout>
);

export default Contact;
